import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import store from '../../../../../store'
import { addAlert, getCachedRegions } from '@/utilities/helpers'

import {
  D_CREATE_PROFILE_ROUTE,
  D_UPDATE_PROFILE_ROUTE,
  D_DELETE_PROFILE_ROUTE,
  D_FIND_PROFILE_ROUTES,
  D_FIND_PROFILE_ROUTES_TOTAL
} from '@/utilities/action-types'

import { genericApiRequests } from '@/utilities/axios-factory'

import {
  getBusStopData,
  getCoarseGeolocation,
  getRoute,
  getRouteByRouteIdList,
  googleGeocodeRequest
} from '@/services/maps-service'

import { getBusStopsForProfileRoute, getProfileRoute } from '@/services/transportation-profile-routes-service'
import { searchOtpRoutes } from '@/services/st-routes-service'
import { geocodeSearch } from '@/services/bus-stops-service'

export default {
  name: 'transportation-accounts-vehicle-itinerary',
  props: {
    signedInUser: Object,
    vehicleId: [String, Number],
    hasUserType: Function,
    hasTransportationRole: Function,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      apiInProgress: false,
      labelSplitter: ' ↔ ',
      editingItem: null,
      clonedEditingItem: null,
      itemNotEdited: true,
      editFormWatchHandle: null,
      routeEditorLoaded: false,
      createdFormExpanded: false,
      routeIdListFromMap: null,
      routeLabelFromMap: null,
      routes: [],
      totalRoutes: 0,
      pagination: null,
      routeTypeRules: [v => {
        if (v && this.routes) {
          const routeType = this.routes.filter(o => !this.editingItem || o.id !== this.editingItem.item.id).filter(o => (o.routeType === v)).length
          console.log('routeType', v, routeType, this.routes)
          if (routeType > 0 && v !== 'weekday') {
            const routeTypeLabel = this.routeTypes.find((rt) => rt.value === v).text
            return `${routeTypeLabel} has already been added`
          }
        } else if (!v) {
          return 'Route type is required'
        }
        return true
      }],
      weekdayRules: [v => {
        if (v && this.routes) {
          const routeType = this.routes.filter(o => !this.editingItem || o.id !== this.editingItem.item.id).filter(o => (o.weekday === v)).length
          if (routeType > 0) {
            const routeTypeLabel = this.weekdays.find((rt) => rt.value === v).text
            return `${routeTypeLabel} has already been added`
          }
        } else if (!v && this.editingItem && this.editingItem.routeType.indexOf('weekday') > -1) {
          return 'Weekday is required'
        }
        return true
      }],
      routeTypes: [
        { text: 'Primary', value: 'primary' },
        { text: 'Primary (Return)', value: 'primaryReturn' },
        { text: 'Weekday', value: 'weekday' },
        { text: 'Weekday (Return)', value: 'weekdayReturn' }
      ],
      weekdays: [
        { text: 'Monday', value: 1 },
        { text: 'Tuesday', value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 },
        { text: 'Saturday', value: 6 },
        { text: 'Sunday', value: 0 }
      ],
      headers: [
        {
          text: 'Route Type',
          align: 'left',
          sortable: true,
          value: 'routeType'
        },
        {
          text: 'Weekday',
          align: 'left',
          sortable: true,
          value: 'weekday'
        },
        {
          text: 'Route Label',
          align: 'left',
          sortable: false
        },
        { text: 'Delete', sortable: false }
      ],
      stMapApis: null,
      markerOptions: { startMarkerClass: 'st-map-marker-start', endMarkerClass: 'st-map-marker-end' },
      mapboxAccessToken: process.env.VUE_APP_MAPBOX_KEY,
      maptilerKey: process.env.VUE_APP_MAPTILER_KEY
    }
  },
  mounted () {
    this.searchRoutes()

    this.stMapApis = {
      genericApiRequests,
      getRouteByRouteIdList,
      getRoute,
      getBusStopsForProfileRoute,
      getTransportationProfileRoute: getProfileRoute,
      addAlert,
      getBusStops: getBusStopData,
      getCachedRegions,
      googleGeocodeRequest,
      getCoarseGeolocation,
      searchRoutes: searchOtpRoutes,
      geocodeRequest: (keywords, longlat) => geocodeSearch({ keywords, longlat })
    }
  },
  methods: {
    initEditingItem (item) {
      if (this.editFormWatchHandle) {
        this.editFormWatchHandle()
        this.editFormWatchHandle = null
      }
      this.editingItem = {
        item: item,
        routeGeometry: item.profileRouteMetadata?.routeGeometry || null,
        routeIdListEdit: item.routeIdList,
        routeLabelEdit: item.profileRouteMetadata?.routeLabel || '',
        routeLabelFromEdit: '',
        routeLabelToEdit: '',
        routeType: item.routeType,
        weekday: item.weekday
      }
      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.clonedEditingItem = _cloneDeep(this.editingItem)
      this.itemNotEdited = true
      if (this.editFormWatchHandle) {
        this.editFormWatchHandle()
      }
      this.editFormWatchHandle = this.$watch('editingItem', (newVal) => {
        this.itemNotEdited = _isEqual(newVal, this.clonedEditingItem)
      }, { deep: true })
    },
    onRouteSelected ({ routeIdList, routeGeometry, from, to } = {}) {
      this.routeIdListFromMap = routeIdList || null

      if (routeGeometry) {
        this.routeGeometryFromMap = routeGeometry
      }

      this.routeLabelFromMap = [from, to].filter(Boolean).join(this.labelSplitter)
    },
    saveSelectedRouteFromMap () {
      this.editingItem.routeIdListEdit = this.routeIdListFromMap
      this.editingItem.routeGeometry = this.routeGeometryFromMap
      this.editingItem.routeLabelEdit = this.routeLabelFromMap
      const labelSplit = this.editingItem.routeLabelEdit.split(this.labelSplitter)
      this.editingItem.routeLabelFromEdit = labelSplit.length ? labelSplit[0] : ''
      this.editingItem.routeLabelToEdit = labelSplit.length ? labelSplit[1] : ''
      this.routeEditorLoaded = false
    },
    cancelSelectedRouteFromMap () {
      this.routeIdListFromMap = null
      this.routeLabelFromMap = null
      this.routeGeometryFromMap = null
      this.routeEditorLoaded = false
    },
    createRouteEntry () {
      this.initEditingItem({ routeIdList: '', routeLabel: '' })
      this.createdFormExpanded = true
    },
    closeRouteEntry () {
      if (this.cancelItemEdit()) {
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }
        this.createdFormExpanded = false
        this.itemNotEdited = true
      }
    },
    saveRouteEntry () {
      this.apiInProgress = true
      this.saveItemEdit().then(() => {
        this.closeRouteEntry()
      }).catch(_ => {}).finally(() => {
        this.apiInProgress = false
      })
    },
    deleteItem (route) {
      this.apiInProgress = true
      if (confirm(`Confirm deleting "${route.profileRouteMetadata?.routeLabel || ''}"`)) {
        store.dispatch(D_DELETE_PROFILE_ROUTE, { id: route.id }).then(() => {
          addAlert({
            message: `Successfully deleted "${route.profileRouteMetadata?.routeLabel || ''}"`,
            type: 'success',
            transient: true
          })
          return this.searchRoutes()
        }).catch((err) => {
          addAlert({
            message: `Error deleting the route fare: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        }).finally(() => {
          this.apiInProgress = false
        })
      }
    },
    toggleItemEditForm (props) {
      if (!this.createdFormExpanded && this.itemNotEdited) {
        props.expanded = !props.expanded
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
        }
        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }
        if (props.expanded) {
          this.createFormExpandedWatcher = this.$watch('createdFormExpanded', (newVal) => {
            if (newVal) {
              props.expanded = false
              this.createFormExpandedWatcher()
              this.createFormExpandedWatcher = null
            }
          })
          this.initEditingItem(props.item)
        } else {
          this.itemNotEdited = true
        }
      }
    },
    closeItemEditForm (props) {
      if (this.cancelItemEdit()) {
        props.expanded = false
        if (this.editFormWatchHandle) {
          this.editFormWatchHandle()
          this.editFormWatchHandle = null
          this.clonedEditingItem = null
        }
        if (this.createFormExpandedWatcher) {
          this.createFormExpandedWatcher()
          this.createFormExpandedWatcher = null
        }
        this.itemNotEdited = true
      }
    },
    isEditingItemValid () {
      return this.$refs && this.$refs.editingForm && this.$refs.editingForm.validate()
    },
    isCreatedItemValid () {
      return this.$refs && this.$refs.creatingForm && this.$refs.creatingForm.validate()
    },
    saveItemEdit (props = undefined) {
      const weekdays = this.routes.filter(o => (o.routeType === 'weekday'))
      const weekdayReturns = this.routes.filter(o => (o.routeType === 'weekdayReturn'))
      if (weekdayReturns.length > weekdays.length) {
        alert('Please add weekdays to match the number of weekday returns')
        return Promise.reject(new Error('Please add weekdays to match the number of weekday returns'))
      }
      if (weekdays.length > 7) {
        alert('Only seven weekday entries can be added')
        return Promise.reject(new Error('Only seven weekday entries can be added'))
      }

      const routeTypeRules = this.routeTypeRules[0](this.editingItem.routeType)
      if (routeTypeRules !== true) {
        alert(routeTypeRules)
        return Promise.reject(new Error(routeTypeRules))
      }
      const weekdayRules = this.weekdayRules[0](this.editingItem.weekday)
      if (weekdayRules !== true) {
        alert(weekdayRules)
        return Promise.reject(new Error(weekdayRules))
      }
      if ((this.editingItem.item.id && this.isEditingItemValid()) || (!this.editingItem.item.id && this.isCreatedItemValid())) {
        if (this.editingItem.routeLabelFromEdit && this.editingItem.routeLabelToEdit) {
          this.editingItem.item.profileRouteMetadata = this.editingItem.item.profileRouteMetadata || {}
          this.editingItem.item.profileRouteMetadata.routeLabel = this.editingItem.routeLabelFromEdit + this.labelSplitter + this.editingItem.routeLabelToEdit
        } else if (!this.editingItem.routeLabelFromEdit || !this.editingItem.routeLabelToEdit) {
          alert('The route label requires a "to" and "from"')
          return Promise.reject(new Error('The route label requires a "to" and "from"'))
        }
        let promise
        const updatedItem = {
          id: this.editingItem.item.id,
          profileRouteMetadata: this.editingItem.item.profileRouteMetadata,
          isPrimary: this.editingItem.routeType === 'primary',
          isPrimaryReturn: this.editingItem.routeType === 'primaryReturn',
          isReturn: this.editingItem.routeType === 'weekdayReturn',
          weekday: this.editingItem.routeType === 'weekday' ? this.editingItem.weekday : undefined,
          routeIdList: this.editingItem.routeIdListEdit,
          routeGeometry: this.editingItem.routeGeometry
        }
        if (this.editingItem.item.id) {
          promise = store.dispatch(D_UPDATE_PROFILE_ROUTE, updatedItem)
        } else {
          promise = store.dispatch(D_CREATE_PROFILE_ROUTE, { transportationProfileId: this.$props.vehicleId, data: updatedItem })
        }
        promise.then((updatedItem) => {
          if (!this.editingItem.item.id) {
            this.searchKeywords = ''
          }
          this.clonedEditingItem = _cloneDeep(this.editingItem)
          this.itemNotEdited = true
          if (props) {
            this.closeItemEditForm(props)
          }
          addAlert({
            message: `Successfully ${this.editingItem.item.id ? 'updated' : 'created'} "${updatedItem.profileRouteMetadata?.routeLabel || ''}"`,
            type: 'success',
            transient: true
          })
          return this.searchRoutes()
        })
        promise.catch((err) => {
          addAlert({
            message: `Error ${this.editingItem.item.id ? 'updating' : 'creating'} the route: ${err && err.error ? err.error.message : JSON.stringify(err)}`,
            type: 'error'
          })
        })
        return promise
      }
    },
    cancelItemEdit () {
      if (!this.itemNotEdited) {
        if (confirm('Are you sure you want to undo your edits?')) {
          this.editingItem = _cloneDeep(this.clonedEditingItem)
          this.itemNotEdited = true
          return true
        }
        return false
      }
      return true
    },
    loadRouteEditor () {
      this.routeEditorLoaded = true
    },
    onPagination () {
      if (this.countryCurrency) {
        this.searchRoutes()
      }
    },
    async searchRoutes () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let filter = {
        limit: rowsPerPage,
        offset,
        where: { transportationProfileId: this.$props.vehicleId || '-1' }
      }
      if (!sortBy) {
        filter.order = ['isPrimary DESC']
      } else {
        filter.order = []
        let sortField
        switch (sortBy) {
          case 'primary':
            sortField = 'isPrimary'
            break
          case 'primaryReturn':
            sortField = 'isPrimaryReturn'
            break
          case 'week':
          case 'weekReturn':
            sortField = 'weekday'
            break
          default:
            sortField = 'isPrimary'
        }
        if (sortBy === 'weekdayReturn') {
          filter.order.push(`isReturn ${descending ? 'DESC' : 'ASC'}`)
        }
        filter.order.push(`${sortField} ${descending ? 'DESC' : 'ASC'}`)
      }
      this.apiInProgress = true
      const [ routes, totalRoutes ] = await Promise.all([
        store.dispatch(D_FIND_PROFILE_ROUTES, filter),
        store.dispatch(D_FIND_PROFILE_ROUTES_TOTAL, filter)
      ])
      this.routes = routes.map(o => {
        o.weekdayLabel = 'n/a'
        if (o.isPrimary) {
          o.routeType = 'primary'
        } else if (o.isPrimaryReturn) {
          o.routeType = 'primaryReturn'
        } else if (o.weekday) {
          o.routeType = 'weekday'
          if (o.isReturn) {
            o.routeType = 'weekdayReturn'
          }
          o.weekdayLabel = this.weekdays.find((rt) => o.weekday === rt.value)
          o.weekdayLabel = o.weekdayLabel ? o.weekdayLabel.text : ''
        }
        o.routeTypeLabel = this.routeTypes.find((rt) => o.routeType === rt.value)
        o.routeTypeLabel = o.routeTypeLabel ? o.routeTypeLabel.text : ''
        return o
      })
      this.totalRoutes = totalRoutes.count
      this.apiInProgress = false
    }
  },
  beforeDestroy () {
    if (typeof this.editFormWatchHandle === 'function') {
      this.editFormWatchHandle()
    }
    if (typeof this.createFormExpandedWatcher === 'function') {
      this.createFormExpandedWatcher()
    }
  }
}
